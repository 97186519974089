import React, { useState, useRef, useEffect } from 'react';
import './App.css';

function App() {
  const [inputCode, setInputCode] = useState('');
  const [output, setOutput] = useState('');
  const lineNumbersRef = useRef(null);
  const textareaRef = useRef(null);

  const runCode = () => {
    const completeHTML = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${extractTitle(inputCode)}</title>
      </head>
      <body>
        ${inputCode}
      </body>
      </html>
    `;
    setOutput(completeHTML);
  };

  const extractTitle = (html) => {
    const titleMatch = html.match(/<title>(.*?)<\/title>/);
    return titleMatch ? titleMatch[1] : 'Document';
  };

  const pasteCode = () => {
    navigator.clipboard.readText().then((text) => {
      setInputCode(text);
    });
  };

  const uploadFile = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/html') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setInputCode(e.target.result);
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a valid HTML file.');
    }
  };

  const clearCode = () => {
    setInputCode('');
    setOutput('');
  };

  const downloadFile = () => {
    if (inputCode.trim()) {
      const blob = new Blob([inputCode], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'output.html'; 
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      alert('No valid HTML content to download.');
    }
  };

  const handleInputChange = (e) => {
    setInputCode(e.target.value);
    updateLineNumbers(e.target.value);
  };

  const updateLineNumbers = (code) => {
    const lineCount = code.split('\n').length;
    const lines = Array.from({ length: lineCount }, (_, i) => i + 1).join('\n');
    if (lineNumbersRef.current) {
      lineNumbersRef.current.textContent = lines;
    }
  };

  useEffect(() => {
    updateLineNumbers(inputCode);
  }, [inputCode]);

  return (
    <div className="App">
      <div className="container">
        <div className="box input-box">
          <div className="button-container-top">
            <button className="paste-button" onClick={pasteCode}>Paste</button>
            <input
              type="file"
              accept=".html"
              onChange={uploadFile}
              className="file-input"
              id="file-input"
            />
            <button
              className="upload-button"
              onClick={() => document.getElementById('file-input').click()}
            >
              Upload File
            </button>
            <button className="clear-button" onClick={clearCode}>Clear</button>
            <button className="download-button" onClick={downloadFile}>Download</button>
          </div>
          <div className="code-editor">
            <div className="line-numbers" ref={lineNumbersRef}></div>
            <textarea
              ref={textareaRef}
              placeholder="Enter your HTML code here..."
              value={inputCode}
              onChange={handleInputChange}
              rows={10} // Ensure initial rows are set for proper sizing
            />
          </div>
          <div className="button-container-bottom">
            <button className="run-button" onClick={runCode}>Run >> </button>

          </div>
        </div>
        <div className="box output-box">
          <iframe title="output" srcDoc={output} />
        </div>
      </div>
    </div>
  );
}

export default App;
